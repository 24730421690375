import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: 'https://bef3e593763ea984b5bd33a0d3f59788@apm.afrimarkets.co.za/27',
    integrations: [Sentry.browserTracingIntegration({ router })],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/app-verify\./],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: import.meta.env.VITE_ENVIRONMENT || 'development',
    release: import.meta.env.VITE_VERSION || 'development',
  })
})
