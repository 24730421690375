import { default as poiEnhzh6WJEkMeta } from "/builds/nht4944308/brands/afrimarkets/afrimarkets-verification-center/gui/pages/evidence/poi.vue?macro=true";
import { default as porOKvvwU4XbdMeta } from "/builds/nht4944308/brands/afrimarkets/afrimarkets-verification-center/gui/pages/evidence/por.vue?macro=true";
import { default as indexnkp4SBf2AHMeta } from "/builds/nht4944308/brands/afrimarkets/afrimarkets-verification-center/gui/pages/index.vue?macro=true";
import { default as national_45idOp7oqf91xyMeta } from "/builds/nht4944308/brands/afrimarkets/afrimarkets-verification-center/gui/pages/national-id.vue?macro=true";
import { default as questionnaireVt0ih9gzJuMeta } from "/builds/nht4944308/brands/afrimarkets/afrimarkets-verification-center/gui/pages/questionnaire.vue?macro=true";
import { default as component_45stubmXY6eG5xN4Meta } from "/builds/nht4944308/brands/afrimarkets/afrimarkets-verification-center/gui/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubmXY6eG5xN4 } from "/builds/nht4944308/brands/afrimarkets/afrimarkets-verification-center/gui/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: poiEnhzh6WJEkMeta?.name ?? "evidence-poi___en",
    path: poiEnhzh6WJEkMeta?.path ?? "/evidence/poi",
    meta: poiEnhzh6WJEkMeta || {},
    alias: poiEnhzh6WJEkMeta?.alias || [],
    redirect: poiEnhzh6WJEkMeta?.redirect,
    component: () => import("/builds/nht4944308/brands/afrimarkets/afrimarkets-verification-center/gui/pages/evidence/poi.vue").then(m => m.default || m)
  },
  {
    name: porOKvvwU4XbdMeta?.name ?? "evidence-por___en",
    path: porOKvvwU4XbdMeta?.path ?? "/evidence/por",
    meta: porOKvvwU4XbdMeta || {},
    alias: porOKvvwU4XbdMeta?.alias || [],
    redirect: porOKvvwU4XbdMeta?.redirect,
    component: () => import("/builds/nht4944308/brands/afrimarkets/afrimarkets-verification-center/gui/pages/evidence/por.vue").then(m => m.default || m)
  },
  {
    name: indexnkp4SBf2AHMeta?.name ?? "index___en",
    path: indexnkp4SBf2AHMeta?.path ?? "/",
    meta: indexnkp4SBf2AHMeta || {},
    alias: indexnkp4SBf2AHMeta?.alias || [],
    redirect: indexnkp4SBf2AHMeta?.redirect,
    component: () => import("/builds/nht4944308/brands/afrimarkets/afrimarkets-verification-center/gui/pages/index.vue").then(m => m.default || m)
  },
  {
    name: national_45idOp7oqf91xyMeta?.name ?? "national-id___en",
    path: national_45idOp7oqf91xyMeta?.path ?? "/national-id",
    meta: national_45idOp7oqf91xyMeta || {},
    alias: national_45idOp7oqf91xyMeta?.alias || [],
    redirect: national_45idOp7oqf91xyMeta?.redirect,
    component: () => import("/builds/nht4944308/brands/afrimarkets/afrimarkets-verification-center/gui/pages/national-id.vue").then(m => m.default || m)
  },
  {
    name: questionnaireVt0ih9gzJuMeta?.name ?? "questionnaire___en",
    path: questionnaireVt0ih9gzJuMeta?.path ?? "/questionnaire",
    meta: questionnaireVt0ih9gzJuMeta || {},
    alias: questionnaireVt0ih9gzJuMeta?.alias || [],
    redirect: questionnaireVt0ih9gzJuMeta?.redirect,
    component: () => import("/builds/nht4944308/brands/afrimarkets/afrimarkets-verification-center/gui/pages/questionnaire.vue").then(m => m.default || m)
  },
  {
    name: component_45stubmXY6eG5xN4Meta?.name ?? undefined,
    path: component_45stubmXY6eG5xN4Meta?.path ?? "/sitemap.xml",
    meta: component_45stubmXY6eG5xN4Meta || {},
    alias: component_45stubmXY6eG5xN4Meta?.alias || [],
    redirect: component_45stubmXY6eG5xN4Meta?.redirect,
    component: component_45stubmXY6eG5xN4
  }
]